<template>
  <div>
    <sign-in
      v-if="displayMap.showSignIn"
      v-bind:signInConfig="options.signInConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></sign-in>
    <sign-up
      v-if="displayMap.showSignUp"
      v-bind:signUpConfig="options.signUpConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></sign-up>
    <amplify-confirm-sign-up
      v-if="displayMap.showConfirmSignUp"
      v-bind:confirmSignUpConfig="options.confirmSignUpConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></amplify-confirm-sign-up>
    <amplify-confirm-sign-in
      v-if="displayMap.showConfirmSignIn"
      v-bind:confirmSignInConfig="options.confirmSignInConfig"
    ></amplify-confirm-sign-in>
    <amplify-forgot-password
      v-if="displayMap.showForgotPassword"
      v-bind:forgotPasswordConfig="options.forgotPasswordConfig"
      v-bind:usernameAttributes="options.usernameAttributes"
    ></amplify-forgot-password>
    <amplify-require-new-password
      v-if="displayMap.requireNewPassword"
      v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
    ></amplify-require-new-password>
    <amplify-set-mfa v-if="displayMap.showMfa" v-bind:mfaConfig="options.mfaConfig"></amplify-set-mfa>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import GetUser from "aws-amplify-vue/src/services/getUser";
import SignUp from "@/components/SignUp";
import SignIn from "@/components/SignIn";
export default {
  name: "Authenticator",
  props: ["authConfig", "authState"],
  components: {
    SignUp,
    SignIn
  },
  data() {
    return {
      user: {
        username: null
      },
      displayMap: {},
      error: "",
      logger: {}
    };
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {
          // hiddenDefaults: ["phone_number"],
          signUpFields: [
            {
              label: "First Name",
              key: "given_name",
              required: true,
              type: "string",
              custom: false,
              displayOrder: 2
            },
            {
              label: "Last Name",
              key: "family_name",
              required: true,
              type: "string",
              custom: false,
              displayOrder: 3
            },
            {
              label: "Email",
              key: "email",
              required: true,
              type: "string",
              custom: false,
              displayOrder: 1
            },
            {
              label: "Phone Number",
              key: "phone_number",
              required: false,
              type: "string",
              custom: false,
              displayOrder: 4
            },
            // {
            //   label: "Phone Number",
            //   key: "phone_number",
            //   required: true,
            //   type: "string",
            //   custom: false,
            //   displayOrder: 4
            // },
            {
              label: "Password",
              key: "password",
              required: true,
              type: "password",
              custom: false,
              displayOrder: 6
            },
            // {
            //   label: "Code",
            //   key: "distributor",
            //   type: "string",
            //   required: false,
            //   custom: true,
            //   displayOrder: 5
            // }
            // {
            //   label: "Store Code",
            //   key: "store",
            //   type: "string",
            //   required: false,
            //   custom: true,
            //   displayOrder: 6
            // }
          ]
        },
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {},
        usernameAttributes: "email"
      };
      return Object.assign(defaults, this.authConfig || {});
    }
  },
  watch: {
    displayMap(val) {
    }
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
    AmplifyEventBus.$on("localUser", user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on("authState", data => {
      this.displayMap = this.updateDisplayMap(data);
      window.scrollTo(0,0)
    });
    GetUser(this.$Amplify)
      .then(val => {
        if (this.authState) {
          return (this.displayMap = this.updateDisplayMap("signUp"));
        }
        else if (val instanceof Error) {
          return (this.displayMap = this.updateDisplayMap("signedOut"));
        }
        this.user = val;
        return (this.displayMap = this.updateDisplayMap("signedIn"));
      })
      .catch(e => this.setError(e));
  },
  methods: {
    updateDisplayMap: state => {
      return {
        showSignIn: state === "signedOut" || state === "signIn",
        showSignUp: state === "signUp",
        showConfirmSignUp: state === "confirmSignUp",
        showConfirmSignIn: state === "confirmSignIn",
        showForgotPassword: state === "forgotPassword",
        showSignOut: state === "signedIn",
        showMfa: state === "setMfa",
        requireNewPassword: state === "requireNewPassword"
      };
    },
    setError: function(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    }
  }
};
</script>
